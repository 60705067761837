.tweet-media{
    max-width: min(500px,40vw);
    height: auto;
}
.tweet-media > img {
    max-width: min(500px,40vw);
    height: auto;
}
.tweet-media > video {
    max-width: min(500px,40vw);
    height: auto;
}