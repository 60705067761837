.query {
  background-color: rgb(8 6 36);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: top;
  align-content: flex-start;
  font-size: calc(8px + 2vmin);
  color: white;
  gap: 24px;
  padding: 48px;
}

a {
  color: #eee;
  outline: 0;
  text-decoration: none;
}

a:focus,
a:hover {
  text-decoration: underline;
}

.query > div {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  display: -webkit-box;
  vertical-align: middle;

}

.query_button {
  padding: 12px;
  display: flex;
  width: 180px;
  height: 2.5em;
  align-items: center;
  justify-content: center;
  background-color:rgb(167, 66, 230);
  color: #eee;
  font-weight: 700;
  text-transform: uppercase;
  border: none;
}

.query__field {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  margin: 14px;
  margin: 0.875rem;
  height: 2em;
  align-items: center;
}

.query__field > p {
  font-size: 60%;
  padding: 12px;
}

