.App {
  background-color: rgb(8 6 36);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  align-content: space-around;
  font-size: calc(10px + 2vmin);
  color: white;
  gap: 24px;
  padding: 24px;
  flex-grow: 1;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}